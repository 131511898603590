export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'ABOUT US',
  headline: 'Work with top notch designers and developers to get amazing products.',
  description: 'Flicken is a service-oriented company providing creative and innovative solutions for your business domain. We believe in exceeding your expectations by delivering thoughtfully innovated eye-catching products on your desk. We take a pride in engineering your requirements into robust software using our mobile, web, cloud and e-commerce capabilities.',
  imgStart: false,
  img: '/images/svg-5.svg',
  alt: 'about',
  dark: true,
  primary: true,
  darkText: false
};

/*export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description: 'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: '/images/svg-4.svg',
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Premium Bank',
  headline: 'Creating an account is extremely easy',
  description: 'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Start Now',
  imgStart: false,
  img: '/images/svg-3.svg',
  alt: 'car',
  primary: false,
  darkText: true
};
*/