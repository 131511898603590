import React from "react";
import JobForm from "../JobForm";

const ApplyForm = () => {
  return (
    <>
      <JobForm />
    </>
  );
};

export default ApplyForm;
