import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import ContactUs from "./pages/contactus";
import Jobs from "./pages/careers";
import ApplyForm from "./pages/formpage";
import MockData from "./DataJson/MockData";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contactus" component={ContactUs} exact />
        <Route path="/careers" component={Jobs} exact />
        <Route path="/detailsform" component={ApplyForm} exact />
        <Route path="/projects" component={MockData} />
      </Switch>
    </Router>
  );
}

export default App;
