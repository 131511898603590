import React from "react";
import {
  Container,
  Content,
  H1,
  Form,
  FormRow,
  FormField,
  Label,
  Input,
  Textarea,
  Select,
  Option,
  ApplyButton,
} from "./JobFormCompo";

function JobForm() {
  return (
    <>
      <Container>
        <Content>
          <H1>Personal Details</H1>
          <Form>
            <FormRow>
              <FormField>
                <Label>First Name</Label>
                <Input type="text" name="firstName" placeholder="" value="" />
              </FormField>
              <FormField className="">
                <Label>Last Name</Label>
                <Input type="text" name="lastName" placeholder="" value="" />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Email</Label>
                <Input type="Email" name="email" placeholder="" value="" />
              </FormField>
              <FormField>
                <Label>City</Label>
                <Input type="text" name="city" placeholder="" />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Address</Label>
                <Input
                  type="text"
                  name=""
                  placeholder=""
                  value=""
                  style={{ width: "95%" }}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Jobs</Label>
                <Select>
                  <Option>Select...</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="Japan">Japan</Option>
                  <Option value="United States">United States</Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                </Select>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Short Bio</Label>
                <Textarea name="bio" placeholder=""></Textarea>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Resume</Label>
                <Input
                  type="file"
                  name="resume"
                  placeholder=""
                  accept=".doc, .pdf, .docx, .ppt, .txt, .png,"
                  value=""
                  style={{ width: "95%" }}
                />
              </FormField>
            </FormRow>
            <ApplyButton>Apply</ApplyButton>
          </Form>
        </Content>
      </Container>
    </>
  );
}

export default JobForm;
