import React, { useEffect } from "react";
import "./Wprocess.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Wprocess() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <section id="workprocess" className="our-blog p-0 m-0 bg-silver">
        <div className="container work-process  pb-5 pt-5">
          <div className="title mb-5 text-center">
            <h2>Our Working Process</h2>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div
                className="process-box process-left"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step">
                      <p className="m-0 p-0">Step</p>
                      <h2 className="ml-5 p-0">01</h2>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5 className="pheading">Project Scoping</h5>
                    <p>
                      <small>
                        We engage our clients to fully understand and scope
                        project requirements so we can set realistic
                        expectations. At FLICKEN we aim to build products that
                        reflect your values and brand identity.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="process-line-l"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="process-point-right-first"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="process-point-left-second"></div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div
                className="process-box process-right"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step">
                      <p className="m-0 p-0">Step</p>
                      <h2 className="ml-5 p-0">02</h2>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5 className="pheading">UI/UX Design</h5>
                    <p>
                      <small>
                        Using agile development, we take an iterative approach
                        to provide clients with personas, wireframes and
                        prototypes. Our clients get a feel for what the end
                        product will look like and can provide us with real time
                        feedback to save on unnecessary development costs.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="process-line-r"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div
                className="process-box process-left"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step">
                      <p className="m-0 p-0">Step</p>
                      <h2 className="ml-5 p-0">03</h2>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5 className="pheading">Development</h5>
                    <p>
                      <small>
                        With the prototype finalized and approved, we kick start
                        the development process with clear milestones for
                        deliverables. We operate a lean, agile shop and work
                        with clients to define an iterative delivery process.
                        Client engagement is one of our key drivers for
                        successful project execution.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="process-line-l-thrd"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="process-point-right-third"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="process-point-left-fourth"></div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div
                className="process-box process-right"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step">
                      <p className="m-0 p-0">Step</p>
                      <h2 className="ml-5 p-0">04</h2>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5 className="pheading">Evaluation</h5>
                    <p>
                      <small>
                        We provide systematic and objective assessment of an
                        on-going or completed project, programme or policy its
                        design, implementation and results. The aim is to
                        determine the relevance and fulfillment of objectives,
                        development efficiency, effectiveness, impact and
                        sustainability.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="process-line-r-fourth"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div
                className="process-box process-left"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="process-step">
                      <p className="m-0 p-0">Step</p>
                      <h2 className="ml-5 p-0">05</h2>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h5 className="pheading">Support</h5>
                    <p>
                      <small>
                        Our relationship with clients does not end with product
                        handover. We are here to provide your business with
                        reliable and timely support needed to ensure that there
                        are zero disruptions with the products we build.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="process-line-l-fifth"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="process-point-right-fifth process-last"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Wprocess;
