import React from 'react';
import { 
  Container, 
  FormWrap, 
  Icon, 
  FormContent, 
  Form, 
  FormInput, 
  FormH1, 
  FormP, 
  FormButton 
} from './ContactUsElements'
import emailjs from "emailjs-com";


const ContactUs = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'flicken-tem', e.target, 'user_93f3Fa3v6b9kjZl3Wcfs9')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset()
}
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>Flicken</Icon>
          <FormContent>
            <Form onSubmit={sendEmail} >
              <FormH1>Lets talk!</FormH1>
              <FormP>Share your ideas & we'll help make them a reality.</FormP>
                <FormInput name='name' placeholder='Full Name' type="text" id="name" required />
                <FormInput name='email' placeholder='Email' type="email" id="email" required />
                <FormInput name='number' placeholder='Phone Number' type="tel" id="telephone" required />
                <FormInput name='message' placeholder='Your Message Here...' type="textarea"  required />
              <FormButton type='submit'>Send</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
    
  )
}

export default ContactUs
