import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkR } from "react-router-dom";

export const Container = styled.div`
  min-height: 692px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow-x: hidden;
`;
export const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 80%;
  }
`;
export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #ea4b23;
  font-weight: bold;
  font-size: 32px;
  width: fit-content;

  &:hover {
    text-decoration: none;
    color: #ea4b23;
  }

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;
export const MainContent = styled.div`
  background: url(https://www.venrup.com/static/careers_hero_bg-1381dbde9466709d6f7c4bd4aceadaef.png)
    center center / cover no-repeat;
`;
export const HeroSection = styled.div`
  color: rgb(255, 255, 255);
  min-height: 100vh;
  padding-bottom: 7%;
  padding-top: 7%;
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  width: 100%;
`;
export const HeaderContainer = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  width: 100%;
`;
export const HeadingRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
`;
export const HeadingCol = styled.div`
  flex: 0 0 auto;
  width: 50%;
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
`;
export const H1 = styled.h1`
  font-weight: 700;
  font-size: 4.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  color: #fff;
  font-family: Lato, system-ui, -apple-system, Arial, Helvetica, sans-serif;
  line-height: 1.2;
`;
export const Line = styled.div`
  font-weight: 400;
  margin-bottom: 30px;
`;
export const ApplyButton = styled(LinkR)`
  border-radius: px;
  background: #ea4b23;
  border-color: #ea4b23;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  white-space: nowrap;
  padding: 10px 22px;
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    text-decoration: none;
    color: #010606;
  }
`;
