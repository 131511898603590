import React, {useState} from 'react'
import Video from '../../videos/video.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ButtonLink, ArrowForward, ArrowRight } from './HeroElements'


const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1> Empowering the Innovations.</HeroH1>
        <HeroP>Flicken provides dedicated remote teams that work closely with you to design and build your idea.</HeroP>
        <HeroBtnWrapper>
          <ButtonLink 
          to='/contactus' 
          onMouseEnter={onHover} 
          onMouseLeave={onHover} 
          primary='true' 
          dark='true'>
            Get a quote {hover ? <ArrowForward /> : <ArrowRight/>}
          </ButtonLink>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
