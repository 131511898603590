import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
export const Content = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow-x: hidden;
`;
export const H1 = styled.h1`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;
export const Form = styled.div`
  background-color: #fff;
  max-width: 50%;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 50px 32px;
  border-radius: 10px;

  @media screen and (max-width: 480px) {
    padding: 32px 32px;
    max-width: none;
  }
`;
export const FormRow = styled.div`
  display: flex;
`;
export const FormField = styled.div`
  width: 100%;
`;
export const Label = styled.label``;
export const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin: 0 10px 20px 0;
  border-radius: 4px;
  border: 1px solid #ced4da;
`;
export const Select = styled.select`
  padding: 11.5px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  width: 95%;
`;
export const Option = styled.option``;
export const ApplyButton = styled.button`
  background: #ea4b23;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  width: 95%;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    text-decoration: none;
    color: #010606;
  }
`;
export const Textarea = styled.textarea`
  width: 95%;
  height: 150px;
`;
