import React from "react";
import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Icon3 from "../../images/svg-3.svg";
// import Icon4 from "../../images/svg-4.svg";
// import Icon5 from "../../images/svg-5.svg";
// import Icon6 from "../../images/svg-6.svg";
import {
  TeamContainer,
  TeamH1,
  TeamWrapper,
  TeamCard,
  Picture,
  Name,
  Post,
} from "./TeamPageComp";

const Services = () => {
  return (
    <TeamContainer id="Team">
      <TeamH1>Our Team</TeamH1>
      <TeamWrapper>
        <TeamCard className="w3-animate-bottom">
          <Picture src={Icon1} />
          <Name>Amar Gul</Name>
          <Post>Frontend Engineer</Post>
        </TeamCard>
        <TeamCard>
          <Picture src={Icon2} />
          <Name>Amar Gul</Name>
          <Post>Frontend Engineer</Post>
        </TeamCard>
        <TeamCard>
          <Picture src={Icon3} />
          <Name>Amar Gul</Name>
          <Post>Frontend Engineer</Post>
        </TeamCard>
      </TeamWrapper>
    </TeamContainer>
  );
};

export default Services;

// import React from "react";
// import "./TeamPageComp.jsx";
// import Icon1 from "../../images/logo.png";
// import { MDBCol } from "mdbreact";

// import {
//   Card,
//   CardBody,
//   Header,
//   Details,
//   Row,
//   Image,
// } from "./TeamPageComp.jsx";

// const TeamPage = () => {
//   return (
//     <>
//       <Card>
//         <CardBody>
//           <Header>Our amazing team</Header>
//           <Details>
//             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
//             error amet numquam iure provident voluptate esse quasi, veritatis
//             totam voluptas nostrum quisquam eum porro a pariatur veniam.
//           </Details>
//           <Row>
//             <MDBCol md="4" className="mb-md-0 mb-5">
//               <Image src={Icon1} />
//               <h4 className="font-weight-bold dark-grey-text my-4">
//                 Maria Kate
//               </h4>
//               <h6 className="text-uppercase grey-text mb-3">Photographer</h6>
//             </MDBCol>
//             <MDBCol md="4" className="mb-md-0 mb-5">
//               <Image
//                 tag="img"
//                 src="https://mdbootstrap.com/img/Photos/Avatars/img%20(27).webp"
//                 className="rounded z-depth-1-half img-fluid"
//                 alt="Sample avatar"
//               />
//               <h4 className="font-weight-bold dark-grey-text my-4">John Doe</h4>
//               <h6 className="text-uppercase grey-text mb-3">
//                 Front-end Developer
//               </h6>
//             </MDBCol>
//             <MDBCol md="4" className="mb-md-0 mb-5">
//               <Image
//                 style={{ paddingTop: "2em" }}
//                 tag="img"
//                 src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).webp"
//                 className="rounded z-depth-1-half img-fluid"
//                 alt="Sample avatar"
//               />
//               <h4 className="font-weight-bold dark-grey-text my-4">
//                 Sarah Melyah
//               </h4>
//               <h6 className="text-uppercase grey-text mb-3">Web Developer</h6>
//             </MDBCol>
//           </Row>
//         </CardBody>
//       </Card>
//     </>
//   );
// };

// export default TeamPage;
