import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import ContactUs from '../ContactUs'

const SigninPage = () => {
  return (
    <>
      <ScrollToTop />
      <ContactUs />
    </>
  )
}

export default SigninPage
