import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const FooterContainer = styled.footer`
  background-color: #000000;
  overflow-x: hidden;
`

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`
export const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
    display: contents;
  }
`
export const FooterLogo = styled.img`
  margin-left: -150px;
  margin-top: 20px;


  @media screen and (max-width: 820px) {
    margin-left: 0;
  }
`

export const FooterLinkWrapper = styled.div`
  display: flex;
  margin-left: 250px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin-right: 260px;
    padding: 10px;
    align-items: center;
  }
  @media screen and (max-width: 800px) {
    margin-right: 260px;
    padding: 10px;
    align-items: center;
  }
`
export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: bold;
`
export const FooterPageLinks = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #EA4B23;
    text-decoration: none;
  }
`
export const FooterAddressWraper = styled.div `
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
}
`
export const FooterAddressItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
    align-items: center;
}
  @media screen and (max-width: 800px) {
    margin: 0;
    padding: 10px;
    width: 100%;
    align-items: center;
}
`
export const FooterAddressTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: bold;
`
export const FooterAddress = styled.p`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  width: 290px;
  display: flex ;
`

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
  margin-left: 120px;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;

  &:hover {
    color: #EA4B23;
    text-decoration: none;
    transition: 0.3s ease-in-out;
  }
`
export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`
export const SocialIcons = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 240px;

  @media screen and (max-width: 480px) {
    display: flex;
    padding: 0 40px;
  }
  @media screen and (max-width: 800px) {
    display: flex;
    padding: 0 40px;
  }
`
export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-right: 20px;
`