import React, { useEffect, useState } from 'react';
import { Container, Heading, Table, TableHeader, TableRow, TableCell, PreviewButton, Tag, Icon } from './style';
import logo from './../images/Red-logo.jpeg'
const MockData = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch mock data from the JSON file
    fetch('/flicken.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setProjects(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handlePreviewClick = (pdfName) => {
    if (pdfName && pdfName.endsWith('.pdf')) {
      window.open(`/pdfs/${pdfName}`, '_blank');
    } else {
      console.error('Invalid PDF URL:', pdfName);
    }
  };

  return (
    <Container>
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '60px', height: '60px', marginRight: '10px',  }} />
        <Icon style={{ fontSize: '60px', margin:"0px 0px 5px 10px" }} to='/'>Flicken</Icon>
      </div>
      <Heading>Projects</Heading>
      <Table>
        <thead>
          <tr>
            <TableHeader>Project Name</TableHeader>
            <TableHeader>Project Type</TableHeader>
            <TableHeader>Tech Stacks</TableHeader>
            <TableHeader>Preview</TableHeader>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <TableRow key={index}>
             <TableCell>
                <a href={project.link} style={{color:"#001"}} target="_blank" rel="noopener noreferrer">
                  {project.name}
                </a>
              </TableCell>
              <TableCell>{project.type}</TableCell>
              <TableCell>
                {Array.isArray(project.tags) && project.tags.length > 0 ? (
                  project.tags.map((tag, i) => (
                    <Tag key={i}>{tag}</Tag>
                  ))
                ) : (
                  'N/A'
                )}
              </TableCell>
              <TableCell>
                <PreviewButton 
                  onClick={() => handlePreviewClick(project.url)}
                >
                  Preview
                </PreviewButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MockData;
