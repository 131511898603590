import React from "react";
import {
  Icon,
  MainContent,
  HeroSection,
  HeaderContainer,
  HeadingRow,
  HeadingCol,
  H1,
  Line,
  ApplyButton,
} from "./CareerComponents";

const Career = () => {
  return (
    <>
      <MainContent>
        <Icon to="/">Flicken</Icon>
        <HeroSection>
          <HeaderContainer>
            <HeadingRow>
              <HeadingCol>
                <H1>
                  Be a part of change. Join the{" "}
                  <span style={{ color: "#ea4b23" }}> Flicken </span>
                </H1>
                <Line>
                  Drive your career and growth by joining our disruptive team
                </Line>
                <ApplyButton to="/detailsform">Apply Now</ApplyButton>
              </HeadingCol>
              <HeadingCol></HeadingCol>
            </HeadingRow>
          </HeaderContainer>
        </HeroSection>
      </MainContent>
    </>
  );
};

export default Career;
