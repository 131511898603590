import styled from "styled-components";

export const TeamContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background: #f7fbff;

  @media screen and (max-width: 480px) {
    height: 1700px;
    border-radius: 10px 10px 0 0;
  }
  @media screen and (min-width: 600px) {
    height: 1700px;
  }

  @media screen and (min-width: 768px) {
    height: 1700px;
  }
  @media screen and (min-width: 800px) {
    height: 1600px;
    border-radius: 10px 10px 0 0;
  }
  @media screen and (min-width: 1024px) {
    height: 1200px;
  }

  @media screen and (min-width: 1280px) {
    height: 110vh;
    border-radius: 10px 10px 0 0;
  }
`;
export const TeamWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 786px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
`;
export const TeamCard = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 10px; */
  height: 470px;
  padding: 30px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-bottom: 4px solid #ea4b23;
  }
`;
export const Picture = styled.img`
  height: 250px;
  width: 250px;
  margin-bottom: 10px;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
export const TeamH1 = styled.h1`
  font-size: 2.5rem;
  color: #212529;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
export const Name = styled.h2`
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
  font-size: 1.25rem;
  color: #212529;
  font-family: Lato, system-ui, -apple-system, Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  /* margin-bottom: 10px; */
  text-align: center;
  /* font-weight: bold; */
`;

export const Post = styled.p`
  color: #ea4b23 !important;
  opacity: 0.6;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  font-size: 1rem;
  text-align: center;
`;

// import styled from "styled-components";

// export const Image = styled.div`
//   width: 300px;
//   height: 300px;
//   display: flex;
//   overflow: hidden;
//   position: relative;
//   font-size: 1.25rem;
//   align-items: center;
//   flex-shrink: 0;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   line-height: 1;
//   user-select: none;
//   border-radius: 50%;
//   justify-content: center;
//   border-radius: 0.25rem !important;
//   max-width: 100%;
// `;
// export const Card = styled.div`
//   text-align: center !important;
//   padding-bottom: 3rem !important;
//   padding-left: 0.25rem !important;
//   padding-right: 0.25rem !important;
//   margin-bottom: 3rem !important;
//   margin-top: 3rem !important;
//   position: relative;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid rgba(0, 0, 0, 0.125);
//   border-radius: 0.25rem;
// `;
// export const CardBody = styled.div`
//   flex: 1 1 auto;
//   padding: 1.25rem;
// `;
// export const Header = styled.h2`
//   font-weight: 700 !important;
//   margin-bottom: 3rem !important;
//   margin-top: 3rem !important;
//   margin-bottom: 0.5rem;
//   font-family: inherit;
//   font-weight: 500;
//   line-height: 1.2;
//   color: inherit;
// `;
// export const Details = styled.p`
//   margin-left: auto !important;
//   margin-right: auto !important;
//   margin-bottom: 3rem !important;
// `;
// export const Row = styled.div`
//   margin-bottom: 0;
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
//   @media only screen and (max-width: 768px) {
//     margin-bottom: 30px;
//   }
// `;

// //   color: transparent;
// //     width: 100%;
// //     height: 100%;
// //     object-fit: cover;
// //     text-align: center;
// //     text-indent: 10000px;

// //  cardbody styled
