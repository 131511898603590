import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
// import { review2 } from "../../images/review2.jpg";
import Icon1 from "../../images/review1.png";
import Icon2 from "../../images/review2.jpg";
// import Icon3 from "../../images/review3.jpg";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "#ea4b23", fontSize: "45px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "#ea4b23", fontSize: "45px" }} />
    </div>
  );
};
const Testimonial = () => {
  return (
    <div className="testimonial">
      <div style={{ width: "50%", textAlign: "center" }}>
        <h2>TESTIMONIALS</h2>
        <hr />
        <Slider
          autoplay="true"
          autoplaySpeed={5000}
          prevArrow={<PreviousBtn />}
          nextArrow={<NextBtn />}
          dots
        >
          <Card
            img={Icon1}
            companyName={"Autohub"}
            details={
              "Flicken did excellent work. They delivered ahead of schedule. Their team are beyond talented and the service we get is outstanding. They delivered same as requested."
            }
            name={"Gitanjali Babbar"}
            field={" Business Development Manager"}
          />
          <Card
            img={Icon2}
            companyName={"Rixtysoft"}
            details={
              "Flicken did excellent work. Was diligent in understanding what was needed and provided exactly as requested. They were easy to work with and delivered a great product."
            }
            name={"Nauman Sharif"}
            field={" CEO"}
          />
          <Card
            // img={Icon3}
            details={
              "VENRUP's deliverables pleased internal stakeholders. The team's UI/UX designs served as a foundation for the company's platform and translated into the success of the product. The team has been easy to communicate with, displaying professionalism and exceptional design skills."
            }
            name={"PAULA WILSON"}
            field={" Media Analyst"}
          />
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ img, companyName, details, name, field }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%", height: "fit-content" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "1px solid #ea4b23",
          padding: 7,
        }}
      />
      <p style={{ marginTop: 10 }}>{companyName} </p>
      <p>
        <span style={{ fontWeight: 400, color: "#ea4b23" }}> {name} </span>,
        {field}
      </p>
      <p> {details}</p>
    </div>
  );
};

export default Testimonial;
