import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import Icon4 from '../../images/svg-4.svg'
import Icon5 from '../../images/svg-5.svg'
import Icon6 from '../../images/svg-6.svg'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServiceElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard className='w3-animate-bottom'>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Data Engineering/Data Analysis</ServicesH2>
          <ServicesP>We help you use your data to its fullest potential, delivering analytics solutions faster to enable actionable results that deliver real transformation.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Web Development</ServicesH2>
          <ServicesP>We are your creative web development team, who aim to leverage
            the latest technological advances with thoughtful
            design and serious engineering to build tailored solutions for any industry.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Mobile App Development</ServicesH2>
          <ServicesP>We develop sleek looking native and hybrid mobile apps for iOS & Android to ensure the customer satisfaction and performance at the core.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>DevOps</ServicesH2>
          <ServicesP>We implement highly advanced tools that are necessary to support the Continuous Delivery along with the DevOps. Our DevOps experts re-engineer your development and deployment processes by raising the quality of software development.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>Blockchain</ServicesH2>
          <ServicesP>We render our blockchain services where our experts help enterprises adopt blockchain technology seamlessly through advisory & consulting services and blockchain development & maintenance services.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon6} className='icon6' />
          <ServicesH2>UI/UX Design</ServicesH2>
          <ServicesP>Modern, minimal and functional design has become the need for any business to gain traction with users. Using best-in-class design principles we provide our clients with innovative branding and UI/UX services.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
