import React from "react";
import Career from "../Careers";

const Jobs = () => {
  return (
    <>
      <Career />
    </>
  );
};

export default Jobs;
