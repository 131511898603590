import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 36px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #ea4b23;
`;

export const TableHeader = styled.th`
  background-color: #ea4b23;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 16px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ea4b23;
`;
export const Tag = styled.span`
  display: inline-block;
  padding: 5px 10px;
  margin: 3px;
  border-radius: 4px;
  background-color: #e0e0e0;
  color: #333;
  font-size: 0.9em;
`;
export const TableCell = styled.td`
  padding: 10px;
  font-size: 14px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 8px;
  }
`;
export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #ea4b23;
  font-weight: bold;
  font-size: 32px;
  width: fit-content;

  &:hover {
    text-decoration: none;
    color: #ea4b23;
  }

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;
export const PreviewButton = styled.button`
  padding: 8px 12px;
  background-color: #ea4b23;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d23d1d;
    border: none;
  }

  @media screen and (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;
