import React from 'react'
import { MdLocationPin } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import image from '../../images/logo.png';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { 
  FooterContainer, 
  FooterWrap, 
  FooterLinkWrapper, 
  FooterLinkItems, 
  FooterLinkContainer, 
  FooterLogo,
  FooterLinkTitle, 
  FooterPageLinks, 
  FooterAddressWraper, 
  FooterAddressItem, 
  FooterAddressTitle,
  FooterAddress, 
  SocialIconLink, 
  SocialIcons, 
  SocialLogo, 
  SocialMedia, 
  SocialMediaWrap, 
  WebsiteRights 
} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }
  const toggleAbout = () => {
    scroll.scrollTo(750);
  }
  const toggleService = () => {
    scroll.scrollTo(1600);
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLogo src={image} alt='logo' height={120} width={120}></FooterLogo>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Links</FooterLinkTitle>
              <FooterPageLinks to='/' onClick={toggleHome}>Home</FooterPageLinks>
              <FooterPageLinks to='/' onClick={toggleAbout} >About</FooterPageLinks>
              <FooterPageLinks to='/' onClick={toggleService} >Services</FooterPageLinks>
              <FooterPageLinks to='/contactus'>Contact Us</FooterPageLinks>
            </FooterLinkItems>
          </FooterLinkWrapper>
          <FooterAddressWraper>
            <FooterAddressItem>
              <FooterAddressTitle>Contact Us</FooterAddressTitle>
              <FooterAddress>
                <MdLocationPin size={35} style={{ marginRight: 8 }} />
                <span>Madina-Heights, E-block, Joher Town, Lahore, Pakistan</span>
              </FooterAddress>
              <FooterAddress>
                <FaPhoneAlt size={16} style={{ marginRight: 8, marginLeft : 4, marginTop : 3 }} />
                <span>+92 324 7075333</span>
              </FooterAddress>
              <FooterAddress>
                <MdAlternateEmail size={22} style={{ marginRight: 8 }} />
                <span>info@flicken.io</span>
              </FooterAddress>
            </FooterAddressItem>
          </FooterAddressWraper>
        </FooterLinkContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Flicken
            </SocialLogo>
            <WebsiteRights>Flicken © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='//www.facebook.com' target='_blank' arial-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='//www.instagram.com' target='_blank' arial-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='//www.linkedin.com/company/flinken' target='_blank' arial-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer >
  )
}

export default Footer
